'use client';

import { useCallback, useSyncExternalStore } from 'react';

export default function useMediaQuery(query, initialMatch: boolean = false) {
  const subscribe = useCallback(
    callback => {
      const matchMedia = window.matchMedia(query);

      // Use deprecated `addListener` and `removeListener` to support Safari < 14
      // https://usehooks-ts.com/react-hook/use-media-query
      if (matchMedia.addListener) {
        matchMedia.addListener(callback);
      } else {
        matchMedia.addEventListener('change', callback);
      }

      return () => {
        if (matchMedia.removeListener) {
          matchMedia.removeListener(callback);
        } else {
          matchMedia.removeEventListener('change', callback);
        }
      };
    },
    [query]
  );

  return useSyncExternalStore(
    subscribe,
    () => {
      return window.matchMedia(query).matches;
    },
    () => {
      return initialMatch;
    }
  );
}
