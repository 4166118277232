import Bugsnag, { BugsnagStatic } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const bugsnagEnabled = process.env.NEXT_PUBLIC_BUGSNAG_ENABLED;
const apiKey = process.env.NEXT_PUBLIC_BUGSNAG_KEY;
const namespace = process.env.NEXT_PUBLIC_DEPLOY_NAMESPACE;
const nextPhase = process.env.NEXT_PHASE;
const appVersion = process.env.NEXT_PUBLIC_GIT_COMMIT;
const nextServer = process.env.NEXT_IS_SERVER;

declare global {
  interface Window {
    Bugsnag: BugsnagStatic;
  }
}

// https://github.com/bugsnag/bugsnag-js/blob/master/examples/js/nextjs/lib/bugsnag.js
/**
 * @deprecated use startClientBugsnag or startServerBugsnag
 */
export function startBugsnag() {
  if (bugsnagEnabled && nextPhase !== 'phase-production-build') {
    if (nextServer) {
      Bugsnag.start({
        appType: 'server',
        apiKey: apiKey || '',
        appVersion,
        releaseStage: namespace,
        plugins: [require('@bugsnag/plugin-aws-lambda'), new BugsnagPluginReact()]
      });
    } else {
      Bugsnag.start({
        appType: 'client',
        apiKey: apiKey || '',
        appVersion,
        releaseStage: namespace,
        plugins: [new BugsnagPluginReact()]
      });
    }

    // this is still necessary because we have window.Bugsnag.notify calls in the monorepo
    if (typeof window !== 'undefined') window.Bugsnag = Bugsnag;
  }
}

export function getBugsnag() {
  return window?.Bugsnag;
}

export default startBugsnag;
