import { useState, useEffect, PropsWithChildren, ReactPortal } from 'react';
import { createPortal } from 'react-dom';

// Based on Next.js modal portal example https://github.com/vercel/next.js/tree/canary/examples/with-portals
export default function ModalPortal({ children }: PropsWithChildren<{}>): ReactPortal | null {
  const [container, setContainer] = useState<Element>();

  useEffect(() => {
    const modalPortalElement = document.getElementById('modalPortal');
    if (modalPortalElement) {
      setContainer(modalPortalElement);
    } else {
      console.error('Missing modalPortal element.');
    }
  }, []);

  return container ? createPortal(children, container) : null;
}
