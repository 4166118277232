import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import type { Context } from '../types/context';

const initialState: Context = {
  currentUser: null,
  proUser: false,
  mobileBrowser: false,
  displayMetric: false,
  languageRegionCode: LanguageRegionCode.English,
  primaryAlgoliaIndexId: '',
  alertsAlgoliaIndexId: '',
  publicBaseUrl: ''
};

export const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContext: (_, action: PayloadAction<Context>) => {
      return { ...action.payload };
    }
  }
});

export const { setContext } = contextSlice.actions;

export default contextSlice.reducer;
