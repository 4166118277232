import { createSlice } from '@reduxjs/toolkit';
import Feature from '../types/Feature';

export const features = createSlice({
  name: 'features',
  initialState: [] as Feature[],
  reducers: {}
});

export default features.reducer;
