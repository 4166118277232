import { get, post, put, destroy } from '@alltrails/shared/api';
import { type BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';

export type BaseArgs = {
  url: string;
  data?: any;
  config?: { params?: any };
  method?: 'get' | 'post' | 'put' | 'delete';
};

export type BaseError = {
  status: number;
  message?: string;
  data?: any;
};

export const baseRequest = async request => {
  try {
    const response = await request();
    return { data: response };
  } catch (error: any) {
    return { error: { status: error.status || 500, message: error.message, data: error.data } };
  }
};

export const baseQuery: BaseQueryFn<BaseArgs, unknown, BaseError> = async ({ url, data, config, method }: BaseArgs) => {
  const params = new URLSearchParams();
  Object.entries(config?.params || {}).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => params.append(`${key}[]`, item));
    } else if (value !== undefined && value !== false && value !== null) {
      params.append(key, value as string);
    }
  });

  if (method === 'post') {
    return baseRequest(() => post(url, data, { params }));
  }

  if (method === 'put') {
    return baseRequest(() => put(url, data, { params }));
  }

  if (method === 'delete') {
    return baseRequest(() => destroy(url));
  }

  return baseRequest(() => get(url, { params }));
};

/**
 * Base `createApi` call for mugen applications to leverage. Other mugen packages (or apps) should extend this API via `injectEndpoints` to add their own logic.
 * NOTE: having a base `createApi` call in a non-application package likely works due to how Turborepo designs their ["Just in time"](https://turbo.build/repo/docs/core-concepts/internal-packages) packages.
 * If we ever consider re-structuring our package compilation, we may want to watch out for this pattern. See https://github.com/alltrails/mugen/pull/2667 for an example of trying to get this working in the `monorepo` instead.
 * A little more context: https://alltrails.slack.com/archives/C055M0B645V/p1719007765936119
 */
export const baseApi = createApi({
  baseQuery,
  reducerPath: 'baseApi',
  endpoints: () => ({})
});
