import Icon from '../components/Icon';

const ArrowSq = Icon(
  'arrow-sq',
  <>
    <path d="M12.75 20.25a.75.75 0 0 1-1.5 0V5.56l-5.47 5.47a.75.75 0 1 1-1.06-1.06l6.75-6.75a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 1 1-1.06 1.06l-5.47-5.47z" />
  </>
);

export default ArrowSq;
