import { createSlice } from '@reduxjs/toolkit';
import type Experiments from '../types/Experiments';

export const experimentsSlice = createSlice({
  name: 'experiments',
  initialState: {} as Experiments,
  reducers: {}
});

export default experimentsSlice.reducer;
