import { shallowEqual, type TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { type Context, contextReducer as context, currencyCodeReducer } from '@alltrails/context';
import { featuresReducer as features, type Feature } from '@alltrails/features';
import { amplitudeAnalyticsSettingsReducer as amplitudeAnalyticsSettings, type AmplitudeAnalyticsSettings } from '@alltrails/amplitude';
import { Experiments, experimentsReducer as experiments } from '@alltrails/experiments';
import { MapState, mapReducer } from '@alltrails/maps';
import { configureStore, createSlice } from '@reduxjs/toolkit';
import Plan from '@alltrails/shared/types/Plan';
import CurrencyCode from '@alltrails/shared/types/CurrencyCode';
import lists, { type ListsState } from '@alltrails/redux/slices/lists';
import userConnections from '@alltrails/redux/slices/userConnections';
import userConnectionLists from '@alltrails/redux/slices/userConnectionLists';
import algoliaConfigs from '@alltrails/redux/slices/algoliaConfigs';
import { AlgoliaConfigs } from '@alltrails/shared/types/algoliaConfigs';
import { baseApi } from '@alltrails/redux-helpers';
import installBanner, { InstallBannerState } from '@alltrails/install-prompt/slices/installBanner';

const planSlice = createSlice({
  name: 'plan',
  initialState: {} as Plan,
  reducers: {}
});

export type PreloadedState = Partial<{
  algoliaConfigs: AlgoliaConfigs;
  amplitudeAnalyticsSettings: AmplitudeAnalyticsSettings;
  browser: UserAgentBrowser;
  context: Context;
  currencyCode: CurrencyCode;
  experiments: Experiments;
  features: Feature[];
  installBanner: InstallBannerState;
  irclickid: string;
  lists: ListsState;
  plan: Plan;
  map: MapState;
}>;

export const reducer = {
  algoliaConfigs,
  amplitudeAnalyticsSettings,
  browser: (state = {}) => state,
  context,
  features,
  installBanner,
  irclickid: (state = '') => state,
  experiments,
  lists,
  currencyCode: currencyCodeReducer,
  plan: planSlice.reducer,
  map: mapReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  userConnections,
  userConnectionLists
};

export const buildStore = (preloadedState?: PreloadedState) =>
  configureStore({
    reducer,
    preloadedState,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ immutableCheck: false }).concat(baseApi.middleware)
  });

export type AppStore = ReturnType<typeof buildStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<AppStore['getState']> & { context?: Context };

// these are intended to use within the app only
export const useDispatch = (): AppDispatch => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = (selector: (state: RootState) => any, equalityFn = shallowEqual) =>
  useAppSelector<RootState, any>(selector, equalityFn);
