import type { OnErrorCallback, Client, NotifiableError } from '@bugsnag/js';
import { getBugsnag } from '@alltrails/stability';

export default function logError(error: NotifiableError, event?: OnErrorCallback) {
  const Bugsnag = getBugsnag() as Client;

  if (Bugsnag) {
    Bugsnag.notify(error, event);
  }

  console.error(error);
}
