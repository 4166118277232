import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { AlgoliaHit, AreaSearchResult, TrailSearchResult } from '@alltrails/search/types/algoliaResultTypes';
import { PointLike } from 'mapbox-gl';
import { Coordinates, LngLat } from '../types/Geo';
import { AllTrailsResult, SerializedCoordinates } from '../types/Results';
import { GeometryCoordinates } from '../types/Route';
import { AdminStyleSettings, BaseStyleId, BoundaryFilterType, DynamicStyleId, StaticStyleId } from '../types/Styles';
import { defaultBaseStyleId } from '../utils/constants';
import { styleSupports3d } from '../utils/styleIdHelpers';
import { dynamicStyleCardConfigs } from '../utils/styleCardConfigs';
import resultsToState from '../utils/resultsToState';
import parksToState from '../utils/parksToState';
import { MapboxStyleIds } from '../utils/getMapboxStyleIds';
import { coordinatesToSerializedCoordinates } from '../utils/serializedCoordinates';

export type MapState = {
  currentCoordinates?: Coordinates; // Coordinates for the blue-dot marker, representing a "current" location on a map
  elevationCoordinates?: Coordinates; // Coordinates representing the hovered location on an elevation chart
  routeCoordinates?: LngLat[]; // Coordinates representing a route polyline

  allowClickEvents?: boolean; // Whether the map should allow click events - defaults to true
  cursor?: string; // CSS cursor to display on the map
  is3dActive?: boolean; // Whether 3d is currently active
  mapboxStyleIds: MapboxStyleIds; // Environment specific ids for our map styles

  allCoordinates: Coordinates[]; // Used to hold an arbitrary set of marker locations - should probably be renamed to have a more specific use or deleted in favor of a different state var
  activeClusterId?: number; // ID of the hovered marker cluster
  clickedResult?: AllTrailsResult; // Algolia result that has been clicked
  hoveredAdminResult?: AlgoliaHit & { popupOffset?: PointLike }; // Algolia result that is currently hovered - this is NOT admin specific anymore as it is also used in the map overlays
  hoveredResult?: AllTrailsResult; // Algolia result that is currently hovered
  clickedSerializedCoordinates?: SerializedCoordinates; // Serialized coordinates of the clicked result (trail, map, or activity)
  hoveredSerializedCoordinates?: SerializedCoordinates; // Serialized coordinates of the hovered result (trail, map, or activity)
  trailheadResults?: string[]; // Array of result (trail, map, or activity) ids that are in the currently hovered or clicked trailhead
  resultIdsBySerializedCoordinates: Record<SerializedCoordinates, string[]>; // A record where the key is the serialized coordinates of a result (trail, map, or activity) and the value is all of the result ids at those given coordinates. In the case of trailheads, this can have more than one element
  resultsById: Record<string, AllTrailsResult>; // A record where the key is the result (trail, map, or activity) id, and the value is the entire result object
  routesById: Record<string, GeometryCoordinates | undefined>; // A record where the key is the result (trail, map, or activity) id and the value is the "route" data (polyline) -- used to render <Polyline />
  isHoveringListViewResult: boolean; // Whether a user is hovering a specific result / park from outside the maps package. In the case that a user is hovering over a trail card from the result list and the trail happens to be in a trailhead cluster, helps the maps package know to render our normal selected trail pin on the map, and not the trailhead marker component.
  isShiftKeyPressedOnHover?: boolean; // Whether the shift key is being pressed while hovering on the map

  activeTrails?: TrailSearchResult[]; // Trail result(s) that are hovered/selected
  trailResults?: Record<string, TrailSearchResult[]>; // Record of the coordinates (lat:lng) of a trailhead to the trail result(s) from that trailhead

  // Park-specific data that follows the new patterns established with the TrailPins components
  activePark?: AreaSearchResult;
  parkResults?: Record<string, AreaSearchResult>; // Record of the coordinates (lat:lng) of a park to the park object

  // Deprecated park data that is based around the old area_presenter in the monolith which doesn't use the algolia area types :/
  allParkCoordinates?: Coordinates[];
  clickedPark?: AllTrailsResult;
  hoveredPark?: AllTrailsResult;
  clickedSerializedParkCoordinates?: SerializedCoordinates;
  hoveredSerializedParkCoordinates?: SerializedCoordinates;
  parkIdsBySerializedCoordinates: Record<SerializedCoordinates, string[]>;
  parksById: Record<string, AllTrailsResult>;

  // Map style/overlay data
  adminStyleSettings?: AdminStyleSettings; // Admin-set settings for specific styles
  baseStyleId: BaseStyleId; // Applied base map style
  dynamicStyleIds: DynamicStyleId[]; // Applied dynamic styles
  heatmapStyleIds: DynamicStyleId[]; // Some DynamicStyleIds have an optional heatmap layer that can also be applied to the map - this will _not_ include the basic heatmap layer
  staticStyleIds: StaticStyleId[]; // Applied static styles
  supportedDynamicStyleIds: DynamicStyleId[]; // Which dynamic styles are enabled for the given map
};

export const initialState: MapState = {
  currentCoordinates: undefined,
  elevationCoordinates: undefined,
  routeCoordinates: undefined,

  allowClickEvents: true,
  cursor: undefined,
  is3dActive: false,
  mapboxStyleIds: {
    mapbox3DBaseMapStyleId: '',
    mapboxAllTrailsStyleId: '',
    mapboxLightPollutionStyleId: '',
    mapboxSatelliteStyleId: '',
    mapboxTerrainStyleId: '',
    mapboxWorldTopoTerrainStyleId: ''
  },

  allCoordinates: [],
  clickedResult: undefined,
  hoveredAdminResult: undefined,
  clickedSerializedCoordinates: undefined,
  hoveredSerializedCoordinates: undefined,
  hoveredResult: undefined,
  trailheadResults: undefined,
  resultIdsBySerializedCoordinates: {},
  resultsById: {},
  routesById: {},
  isHoveringListViewResult: false,
  isShiftKeyPressedOnHover: false,

  allParkCoordinates: [],
  clickedPark: undefined,
  hoveredPark: undefined,
  clickedSerializedParkCoordinates: undefined,
  hoveredSerializedParkCoordinates: undefined,
  parkIdsBySerializedCoordinates: {},
  parksById: {},

  adminStyleSettings: undefined,
  baseStyleId: defaultBaseStyleId,
  heatmapStyleIds: [],
  dynamicStyleIds: [],
  staticStyleIds: [],
  supportedDynamicStyleIds: []
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    updateCurrentCoordinates: (state, action: PayloadAction<Coordinates | undefined>) => {
      state.currentCoordinates = action.payload;
    },
    updateElevationCoordinates: (state, action: PayloadAction<Coordinates | undefined>) => {
      state.elevationCoordinates = action.payload;
    },
    updateRouteCoordinates: (state, action: PayloadAction<LngLat[] | undefined>) => {
      state.routeCoordinates = action.payload;
    },

    updateCursor: (state, action: PayloadAction<string | undefined>) => {
      state.cursor = action.payload;
    },
    updateIs3dActive: (state, action: PayloadAction<boolean>) => {
      state.is3dActive = action.payload;
    },

    updateActiveClusterId: (state, action: PayloadAction<number>) => {
      if (!state.clickedResult && action.payload !== state.activeClusterId) {
        state.activeClusterId = action.payload;
        state.hoveredSerializedCoordinates = undefined;
        state.hoveredResult = undefined;
        state.trailheadResults = undefined;
      }
    },
    clearActiveClusterId: state => {
      state.activeClusterId = undefined;
    },
    updateClickedResult: (state, action: PayloadAction<AllTrailsResult | undefined>) => {
      state.clickedResult = action.payload;
    },
    updateHoveredAdminResult: (state, action: PayloadAction<AlgoliaHit | undefined>) => {
      state.hoveredAdminResult = action.payload;
    },
    updateHoveredResult: (state, action: PayloadAction<AllTrailsResult | undefined>) => {
      state.hoveredResult = action.payload;
    },
    updateClickedCoordinates: (state, action: PayloadAction<{ coordinates?: SerializedCoordinates; index: number }>) => {
      const { coordinates, index } = action.payload;
      // if a trailhead pin was clicked, grab the existing hovered or clicked pin coordinates

      const clickedSerializedCoordinates = coordinates || state.hoveredSerializedCoordinates || state.clickedSerializedCoordinates;

      state.clickedSerializedCoordinates = clickedSerializedCoordinates;
      const resultIds = clickedSerializedCoordinates ? state.resultIdsBySerializedCoordinates[clickedSerializedCoordinates] : undefined;
      state.clickedResult = resultIds && state.resultsById[resultIds[index]];
      state.hoveredSerializedCoordinates = undefined;
      state.hoveredResult = undefined;
      state.isShiftKeyPressedOnHover = false;

      if (resultIds && resultIds?.length > 1) {
        state.trailheadResults = resultIds;
      } else {
        state.trailheadResults = undefined;
      }
    },
    clearClickedCoordinates: state => {
      state.clickedResult = undefined;
      state.hoveredResult = undefined;
      state.hoveredSerializedCoordinates = undefined;
      state.clickedSerializedCoordinates = undefined;
      state.trailheadResults = undefined;
      state.isShiftKeyPressedOnHover = false;
    },
    updateHoveredCoordinates: (state, action: PayloadAction<{ coordinates?: SerializedCoordinates; index?: number; resultId?: string }>) => {
      const { coordinates, index, resultId } = action.payload;
      const hoveredCoordinates = coordinates || state.hoveredSerializedCoordinates;
      const isNewHoveredResult = coordinates !== state.hoveredSerializedCoordinates;
      // if the user is hovering over a trailhead result in the list view, and then hovers over another trailhead result from the same trailhead,
      // we still need to update the currentlyHoveredResult
      const isNewResultInSameTrailhead = resultId && state.hoveredResult?.ID !== resultId;

      if (!state.clickedResult && (isNewHoveredResult || isNewResultInSameTrailhead)) {
        state.hoveredSerializedCoordinates = hoveredCoordinates;
        const resultIds = hoveredCoordinates && state.resultIdsBySerializedCoordinates[hoveredCoordinates];
        const newResultId = typeof index === 'number' ? resultIds?.[index] : resultId;
        state.hoveredResult = newResultId ? state.resultsById[newResultId] : undefined;
        state.activeClusterId = undefined;

        if (resultIds && resultIds?.length > 1) {
          state.trailheadResults = resultIds;
        } else {
          state.trailheadResults = undefined;
        }
      }
    },
    clearHoveredCoordinates: state => {
      if (!state.clickedSerializedCoordinates && (state.hoveredResult || state.activeClusterId)) {
        state.hoveredSerializedCoordinates = undefined;
        state.hoveredResult = undefined;
        state.trailheadResults = undefined;
        state.isHoveringListViewResult = false;
        state.activeClusterId = undefined;
        state.isShiftKeyPressedOnHover = false;
      }
    },
    setIsHoveringListViewResult: state => {
      if (!state.clickedSerializedCoordinates) {
        state.isHoveringListViewResult = true;
        state.isShiftKeyPressedOnHover = false;
      }
    },
    updateIsShiftKeyPressedOnHover: (state, action: PayloadAction<boolean>) => {
      state.isShiftKeyPressedOnHover = action.payload;
    },
    updateResults: (state, action: PayloadAction<{ results: AllTrailsResult[]; isMobile?: boolean }>) => {
      const { results: newResults, isMobile } = action.payload;

      const { allCoordinates, resultIdsBySerializedCoordinates, resultsById, routesById, shouldClearClickedResults } = resultsToState(
        newResults,
        state,
        isMobile
      );

      state.allCoordinates = allCoordinates;
      state.resultIdsBySerializedCoordinates = resultIdsBySerializedCoordinates;
      state.resultsById = resultsById;
      state.routesById = routesById;

      if (shouldClearClickedResults) {
        state.clickedResult = undefined;
        state.hoveredResult = undefined;
        state.hoveredSerializedCoordinates = undefined;
        state.clickedSerializedCoordinates = undefined;
        state.trailheadResults = undefined;
        state.isShiftKeyPressedOnHover = false;
      }
    },

    updateAdminStyleSettings: (state, action: PayloadAction<AdminStyleSettings | undefined>) => {
      state.adminStyleSettings = action.payload;
    },
    addOrRemoveBoundaryFilter: (state, action: PayloadAction<BoundaryFilterType>) => {
      const indexToRemove = state.adminStyleSettings?.boundaryFilters?.indexOf(action.payload);
      if (indexToRemove !== undefined && indexToRemove > -1) {
        state.adminStyleSettings?.boundaryFilters?.splice(indexToRemove, 1);
      } else {
        if (!state.adminStyleSettings?.boundaryFilters) {
          state.adminStyleSettings = { ...state.adminStyleSettings, boundaryFilters: [action.payload] };
        } else {
          state.adminStyleSettings?.boundaryFilters?.push(action.payload);
        }
      }
    },
    updateBaseStyleId: (state, action: PayloadAction<BaseStyleId>) => {
      const styleId = action.payload;
      if (!styleSupports3d(styleId)) {
        state.is3dActive = false;
      }
      state.baseStyleId = styleId;
    },
    toggleDynamicStyleId: (state, action: PayloadAction<DynamicStyleId>) => {
      const styleId = action.payload;
      const ids = new Set(state.dynamicStyleIds);
      if (ids.has(styleId)) {
        ids.delete(styleId);

        // Turn off the corresponding heatmap if the dynamic style is deselected
        if (dynamicStyleCardConfigs[styleId].hasHeatmap) {
          const heatmapIds = new Set(state.heatmapStyleIds);
          if (heatmapIds.has(styleId)) {
            heatmapIds.delete(styleId);
            state.heatmapStyleIds = Array.from(heatmapIds);
          }
        }
      } else {
        ids.add(styleId);
      }
      state.dynamicStyleIds = Array.from(ids);
    },
    toggleHeatmapStyleId: (state, action: PayloadAction<DynamicStyleId>) => {
      const ids = new Set(state.heatmapStyleIds);
      if (ids.has(action.payload)) {
        ids.delete(action.payload);
      } else {
        ids.add(action.payload);
      }
      state.heatmapStyleIds = Array.from(ids);
    },
    toggleStaticStyleId: (state, action: PayloadAction<StaticStyleId>) => {
      const ids = new Set(state.staticStyleIds);
      if (ids.has(action.payload)) {
        ids.delete(action.payload);
      } else {
        ids.add(action.payload);
      }
      state.staticStyleIds = Array.from(ids);
    },
    updateSupportedDynamicStyleIds: (state, action: PayloadAction<DynamicStyleId[]>) => {
      state.supportedDynamicStyleIds = action.payload;
    },
    resetMapStyles: state => {
      state.adminStyleSettings = undefined;
      state.baseStyleId = defaultBaseStyleId;
      state.heatmapStyleIds = [];
      state.dynamicStyleIds = [];
      state.staticStyleIds = [];
    },
    updateRoutesById: (state, action: PayloadAction<{ resultId?: string; routeCoordinates: GeometryCoordinates }>) => {
      const { resultId, routeCoordinates } = action.payload;
      const isValidResponse = !!resultId && !!routeCoordinates;
      if (isValidResponse) {
        // Update the store even if this result is no longer selected to avoid future API calls
        state.routesById[resultId] = routeCoordinates;
      }
    },
    updateAllowClickEvents: (state, action: PayloadAction<boolean>) => {
      state.allowClickEvents = action.payload;
    },
    updateActiveTrails: (state, action: PayloadAction<TrailSearchResult[] | undefined>) => {
      state.activeTrails = action.payload;
    },
    updateTrailResults: (state, action: PayloadAction<TrailSearchResult[]>) => {
      let activeTrailCount = 0;
      const activeTrailIds = state.activeTrails?.map(trail => trail.ID);
      const trailResults: Record<string, TrailSearchResult[]> = action.payload.reduce<Record<string, TrailSearchResult[]>>((newResults, result) => {
        if (!result._geoloc) {
          return newResults;
        }
        if (activeTrailIds?.includes(result.ID)) {
          activeTrailCount += 1;
        }
        const trailheadCoordinates = coordinatesToSerializedCoordinates(result._cluster_geoloc ?? result._geoloc);
        const resultsAtCoordinates = newResults[trailheadCoordinates] ?? [];
        return { ...newResults, [trailheadCoordinates]: [...resultsAtCoordinates, result] };
      }, {});
      state.trailResults = trailResults;

      // Reset activeTrails if the current active trails are not all contained in the new trail results
      if (activeTrailCount !== state.activeTrails?.length) {
        state.activeTrails = undefined;
      }
    },
    updateActivePark: (state, action: PayloadAction<AreaSearchResult | undefined>) => {
      state.activePark = action.payload;
    },
    updateParkResults: (state, action: PayloadAction<AreaSearchResult[]>) => {
      let hasActivePark = false;
      const parkResults: Record<string, AreaSearchResult> = action.payload.reduce<Record<string, AreaSearchResult>>((newResults, result) => {
        if (!result._geoloc) {
          return newResults;
        }
        if (state.activePark && state.activePark.ID === result.ID) {
          hasActivePark = true;
        }
        const serializedCoordinates = coordinatesToSerializedCoordinates(result._geoloc);
        return { ...newResults, [serializedCoordinates]: result };
      }, {});

      // Compare old and new results to prevent unnecessary rerenders
      const previousParkCoordinates = Object.keys(state.parkResults || {});
      const newParkCoordinates = Object.keys(parkResults);
      if (
        previousParkCoordinates.length !== newParkCoordinates.length ||
        !previousParkCoordinates.every(coords => newParkCoordinates.includes(coords))
      ) {
        state.parkResults = parkResults;

        if (!hasActivePark) {
          state.activePark = undefined;
        }
      }
    },
    updateHoveredParkCoordinates: (state, action: PayloadAction<SerializedCoordinates>) => {
      const hoveredCoordinates = action.payload || state.hoveredSerializedParkCoordinates;
      const isNewHoveredPark = action.payload !== state.hoveredSerializedParkCoordinates;

      if (!state.clickedPark && isNewHoveredPark) {
        state.hoveredSerializedParkCoordinates = hoveredCoordinates;
        const parkIds = hoveredCoordinates && state.parkIdsBySerializedCoordinates[hoveredCoordinates];
        const newParkId = parkIds?.[0];
        state.hoveredPark = newParkId ? state.parksById[newParkId] : undefined;
      }
    },
    clearHoveredParkCoordinates: state => {
      if (!state.clickedSerializedParkCoordinates && state.hoveredPark) {
        state.hoveredSerializedParkCoordinates = undefined;
        state.hoveredPark = undefined;
      }
    },
    updateClickedParkCoordinates: (state, action: PayloadAction<{ coordinates?: SerializedCoordinates }>) => {
      const { coordinates } = action.payload;
      const clickedSerializedCoordinates = coordinates || state.hoveredSerializedParkCoordinates;
      state.clickedSerializedParkCoordinates = clickedSerializedCoordinates;
      const parkIds = clickedSerializedCoordinates ? state.parkIdsBySerializedCoordinates[clickedSerializedCoordinates] : undefined;
      state.clickedPark = parkIds && state.parksById[parkIds[0]];
      state.hoveredSerializedParkCoordinates = undefined;
      state.hoveredPark = undefined;
    },
    clearClickedParkCoordinates: state => {
      state.clickedPark = undefined;
      state.hoveredPark = undefined;
      state.hoveredSerializedParkCoordinates = undefined;
      state.clickedSerializedParkCoordinates = undefined;
    },
    updateParks: (state, action: PayloadAction<AllTrailsResult[]>) => {
      const newParks = action.payload;
      const { parkIdsBySerializedCoordinates, parksById, allParkCoordinates } = parksToState(newParks, state);

      state.allParkCoordinates = allParkCoordinates;
      state.parkIdsBySerializedCoordinates = parkIdsBySerializedCoordinates;
      state.parksById = parksById;
    }
  }
});

export const {
  updateCurrentCoordinates,
  updateElevationCoordinates,
  updateRouteCoordinates,

  updateCursor,
  updateIs3dActive,

  updateActiveClusterId,
  clearActiveClusterId,
  updateClickedResult,
  updateHoveredAdminResult,
  updateHoveredResult,
  updateClickedCoordinates,
  clearClickedCoordinates,
  updateHoveredCoordinates,
  clearHoveredCoordinates,
  setIsHoveringListViewResult,
  updateIsShiftKeyPressedOnHover,
  updateResults,
  updateRoutesById,
  updateAllowClickEvents,

  updateActiveTrails,
  updateTrailResults,

  updateActivePark,
  updateParkResults,

  updateHoveredParkCoordinates,
  clearHoveredParkCoordinates,
  updateClickedParkCoordinates,
  clearClickedParkCoordinates,
  updateParks,

  updateAdminStyleSettings,
  addOrRemoveBoundaryFilter,
  updateBaseStyleId,
  toggleDynamicStyleId,
  toggleHeatmapStyleId,
  toggleStaticStyleId,
  updateSupportedDynamicStyleIds,
  resetMapStyles
} = mapSlice.actions;

export const mapReducer = mapSlice.reducer;
