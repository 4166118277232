'use client';

import { forwardRef, Ref, type ComponentProps, type MouseEvent, type PropsWithChildren } from 'react';
import classNames from 'classnames';
import NextLink from 'next/link';
import { useLanguageRegionCode } from '@alltrails/language';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import IconRenderer, { type IconDefinition } from '../IconRenderer';
import type { LinkRel, LinkTarget, Size } from '../../types';
import LinkOrNativeAnchor from '../LinkOrNativeAnchor/LinkOrNativeAnchor';
import isAbsoluteLink from './isAbsoluteLink';
import styles from './styles/Link.module.scss';

export type LinkProps = {
  className?: string;
  href?: string;
  icon?: IconDefinition<'orientation'>;
  itemProp?: string;
  noUnderline?: boolean;
  onClick?: (e: MouseEvent) => void;
  prefetch?: ComponentProps<typeof NextLink>['prefetch'];
  rel?: LinkRel | LinkRel[];
  size?: Size<'sm' | 'md' | 'lg'>; // you can use a Link inside of block of text where we want the font size to be cascaded so don't require a size
  target?: LinkTarget;
  testId?: string;
  title?: string;
  variant?: 'primary' | 'secondary' | 'inverse';
  outsideOfMugen?: boolean;
};

// https://www.figma.com/file/KEnmNcWLwGNXOhw5iHm7n6/DNLI-17%3A-Update-link-styles?type=design&node-id=0-1&t=DSQwqHQG9EvSBRhg-0
const Link = forwardRef<HTMLElement, PropsWithChildren<LinkProps>>(
  (
    {
      children,
      className,
      href,
      icon,
      itemProp,
      noUnderline,
      onClick,
      prefetch = false,
      size,
      target,
      testId,
      title,
      variant = 'primary',
      rel,
      outsideOfMugen
    },
    ref
  ): JSX.Element => {
    const languageRegionCode = useLanguageRegionCode();

    const classes = classNames(className, styles.link, size && styles[size], styles[variant], {
      [styles.noUnderline]: noUnderline,
      [styles.icon]: icon
    });

    const content = icon ? (
      <>
        <IconRenderer icon={icon} defaults={{ color: 'currentColor', size }} />
        <span>{children}</span>
      </>
    ) : (
      children
    );

    if (!href) {
      return (
        <button ref={ref as Ref<HTMLButtonElement>} className={classes} onClick={onClick} data-testid={testId} type="button" aria-label={title}>
          {content}
        </button>
      );
    }

    const isAbsolute = isAbsoluteLink(href);

    const linkProps = {
      className: classes,
      onClick,
      href: isAbsolute ? href : wrapUrlSafe(href, languageRegionCode),
      'data-testid': testId,
      rel,
      target,
      title,
      itemProp,
      prefetch
    };

    return (
      <LinkOrNativeAnchor outsideOfMugen={outsideOfMugen} ref={ref as Ref<HTMLAnchorElement>} {...linkProps}>
        {content}
      </LinkOrNativeAnchor>
    );
  }
);

export default Link;
