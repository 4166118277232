import { MapState } from '../redux/reducer';
import { AllTrailsResult, SerializedCoordinates } from '../types/Results';
import { coordinatesToSerializedCoordinates, serializedCoordinatesToCoordinates } from './serializedCoordinates';

export function resultToSerializedCoordinates(result: AllTrailsResult): SerializedCoordinates {
  // eslint-disable-next-line no-underscore-dangle
  const coordinates = result?._cluster_geoloc ? result._cluster_geoloc : result._geoloc;
  return coordinatesToSerializedCoordinates(coordinates);
}

export default function resultsToState(results: AllTrailsResult[], previousState: MapState, isMobile?: boolean) {
  const resultsWithStringIDs = results.filter(result => ({
    ...result,
    ID: String(result.ID)
  }));
  const { resultIdsBySerializedCoordinates, resultsById, routesById, shouldClearClickedResults } = resultsWithStringIDs.reduce(
    (memo, result) => {
      const serializedCoordinates = resultToSerializedCoordinates(result);
      const previousRoutesById = previousState.routesById;
      const previousClickedResult = previousState.clickedResult;

      const setResultIdsBySerializedCoordinates = () => {
        if (!memo.resultIdsBySerializedCoordinates[serializedCoordinates]) {
          memo.resultIdsBySerializedCoordinates[serializedCoordinates] = [result.ID];
        } else {
          memo.resultIdsBySerializedCoordinates[serializedCoordinates].push(result.ID);
        }
      };

      const setRoutesById = () => {
        if (previousRoutesById[result.ID]) {
          memo.routesById[result.ID] = previousRoutesById[result.ID];
        }
      };

      const setShouldClearClickedResults = () => {
        if (isMobile && previousClickedResult && result.ID === previousClickedResult.ID) {
          memo.shouldClearClickedResults = false;
        }
      };

      setResultIdsBySerializedCoordinates();
      setRoutesById();
      setShouldClearClickedResults();

      memo.resultsById[result.ID] = result;

      return memo;
    },
    {
      resultIdsBySerializedCoordinates: {},
      resultsById: {},
      routesById: {},
      shouldClearClickedResults: isMobile // On mobile, we deselect pins if they are moved off of the screen
    }
  );

  const allCoordinates = Object.keys(resultIdsBySerializedCoordinates).map(serializedCoordinates =>
    serializedCoordinatesToCoordinates(serializedCoordinates as SerializedCoordinates)
  );

  return {
    allCoordinates,
    resultIdsBySerializedCoordinates,
    resultsById,
    routesById,
    shouldClearClickedResults
  };
}
