import { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import IconRenderer, { IconDefinition } from '../IconRenderer';
import LinkInfoRenderer from '../LinkInfoRenderer';
import LoadingSpinner from '../LoadingSpinner';
import type { LinkInfo, Size } from '../../types';
import PlusBadge from '../PlusBadge';
import styles from './styles/IconButton.module.scss';

type IconButtonProps = {
  className?: string;
  disabled?: boolean;
  icon: IconDefinition<'orientation' | 'color'>;
  linkInfo?: LinkInfo;
  loading?: boolean;
  onBlur?: () => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onFocus?: () => void;
  showPlusBadge?: boolean;
  size?: Size<'sm' | 'md' | 'lg'>;
  stopPropagation?: boolean;
  tabIndex?: number;
  testId: string;
  title: string;
  ariaLabel?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  variant?: 'default' | 'elevated' | 'flat' | 'primary' | 'transparent';
};

const IconButton = forwardRef(
  (
    {
      className,
      disabled,
      icon,
      linkInfo,
      loading,
      onBlur,
      onClick,
      onKeyDown,
      onFocus,
      showPlusBadge,
      size = 'md',
      stopPropagation = false,
      tabIndex,
      testId,
      title,
      ariaLabel,
      type = 'button',
      variant = 'default'
    }: IconButtonProps,
    ref: React.Ref<HTMLElement>
  ) => {
    const onButtonClick = useMemo(
      () => (e: React.MouseEvent<HTMLElement>) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        if (onClick) {
          onClick(e);
        }
      },
      [onClick, stopPropagation]
    );
    const handleKeyDown = useMemo(
      () => (e: React.KeyboardEvent<HTMLElement>) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
        if (onKeyDown) {
          onKeyDown(e);
        }
      },
      [onKeyDown, stopPropagation]
    );
    const iconSize = size === 'sm' ? 'sm' : 'md';
    const content = useMemo(() => {
      const plusBadge = showPlusBadge ? <PlusBadge className={styles.plusBadge} size="sm" /> : null;
      if (loading && !disabled) {
        return (
          <>
            <LoadingSpinner size={iconSize} testId={`${testId}-loading-spinner`} />
            {plusBadge}
          </>
        );
      }

      return (
        <>
          <IconRenderer icon={icon} defaults={{ color: 'currentColor', size: iconSize }} />
          {plusBadge}
        </>
      );
    }, [disabled, icon, iconSize, loading, showPlusBadge, testId]);

    const classes = classNames(
      className,
      styles.button,
      styles[size],
      styles[variant],
      loading && styles.loading,
      disabled && styles.disabled // An anchor tag cannot be disabled, so we apply a disabled class instead
    );

    const buttonElement = useMemo(() => {
      if (linkInfo) {
        if (disabled) {
          return (
            <span className={classes} title={title} aria-label={ariaLabel || title} data-testid={testId} ref={ref}>
              {content}
            </span>
          );
        }

        return (
          <LinkInfoRenderer
            className={classes}
            linkInfo={linkInfo}
            onClick={loading ? undefined : onButtonClick}
            onKeyDown={loading ? undefined : handleKeyDown}
            title={title}
            ariaLabel={ariaLabel || title}
            testId={testId}
            tabIndex={tabIndex}
            ref={ref as React.Ref<HTMLAnchorElement>}
          >
            {content}
          </LinkInfoRenderer>
        );
      }

      return (
        <button
          className={classes}
          onClick={loading ? undefined : onButtonClick}
          onKeyDown={loading ? undefined : handleKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          title={title}
          aria-label={ariaLabel || title}
          data-testid={testId}
          type={type}
          tabIndex={tabIndex}
          ref={ref as React.Ref<HTMLButtonElement>}
        >
          {content}
        </button>
      );
    }, [linkInfo, classes, loading, onButtonClick, handleKeyDown, onBlur, onFocus, disabled, title, ariaLabel, testId, type, tabIndex, ref, content]);

    return buttonElement;
  }
);

export default IconButton;
