import { BaseStyleId, DynamicStyleId, StaticStyleId, StyleId } from '../types/Styles';
import { baseStyleCardConfigs, dynamicStyleCardConfigs, staticStyleCardConfigs } from './styleCardConfigs';

export const isBaseStyle = (id: StyleId): id is BaseStyleId => {
  return Object.keys(baseStyleCardConfigs).includes(id);
};

export const isStaticStyle = (id: StyleId): id is StaticStyleId => {
  return Object.keys(staticStyleCardConfigs).includes(id);
};

export const isDynamicStyle = (id: StyleId): id is DynamicStyleId => {
  return Object.keys(dynamicStyleCardConfigs).includes(id);
};

export const styleSupports3d = (style: BaseStyleId) => {
  return baseStyleCardConfigs[style].supports3d;
};
