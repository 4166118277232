import classNames from 'classnames';
import type { Size } from '../../types';
import styles from './styles/styles.module.scss';

type LoadingSpinnerSize = Size<'sm' | 'md' | 'lg'>;

const sizeToPx: Record<LoadingSpinnerSize, number> = {
  sm: 16,
  md: 24,
  lg: 32
};

type LoadingSpinnerProps = {
  className?: string;
  size?: LoadingSpinnerSize;
  testId?: string;
};

export default function LoadingSpinner({ className, size = 'md', testId }: LoadingSpinnerProps) {
  const sizePx = `${sizeToPx[size]}px`;

  return (
    <svg
      className={classNames(styles.container, className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      role="presentation"
      aria-hidden="true"
      focusable="false"
      data-testid={testId}
      style={{
        height: sizePx,
        width: sizePx,
        fill: 'currentColor'
      }}
    >
      <circle opacity="1" cx="8" cy="1.5" r="1.5" />
      <circle opacity="0.08" cx="12.6" cy="3.4" r="1.5" />
      <circle opacity="0.16" cx="14.5" cy="8" r="1.5" />
      <circle opacity="0.3" cx="12.6" cy="12.6" r="1.5" />
      <circle opacity="0.44" cx="8" cy="14.5" r="1.5" />
      <circle opacity="0.58" cx="3.4" cy="12.6" r="1.5" />
      <circle opacity="0.72" cx="1.5" cy="8" r="1.5" />
      <circle opacity="0.86" cx="3.4" cy="3.4" r="1.5" />
    </svg>
  );
}