import { MapState } from '../redux/reducer';
import { AllTrailsResult, SerializedCoordinates } from '../types/Results';
import { coordinatesToSerializedCoordinates, serializedCoordinatesToCoordinates } from './serializedCoordinates';

export function parkToSerializedCoordinates(park: AllTrailsResult): SerializedCoordinates {
  // eslint-disable-next-line no-underscore-dangle
  const coordinates = park._geoloc;
  return coordinatesToSerializedCoordinates(coordinates);
}

export default function parksToState(parks: AllTrailsResult[], previousState: MapState) {
  const parksWithStringIDs = parks.filter(park => ({
    ...park,
    ID: String(park.ID)
  }));

  const { parkIdsBySerializedCoordinates, parksById } = parksWithStringIDs.reduce(
    (memo, park) => {
      const serializedCoordinates = parkToSerializedCoordinates(park);

      const setParkIdsBySerializedCoordinates = () => {
        if (!memo.parkIdsBySerializedCoordinates[serializedCoordinates]) {
          memo.parkIdsBySerializedCoordinates[serializedCoordinates] = [park.ID];
        } else {
          memo.parkIdsBySerializedCoordinates[serializedCoordinates].push(park.ID);
        }
      };

      setParkIdsBySerializedCoordinates();

      memo.parksById[park.ID] = park;

      return memo;
    },
    {
      parkIdsBySerializedCoordinates: previousState.parkIdsBySerializedCoordinates,
      parksById: previousState.parksById
    }
  );

  const allParkCoordinates = Object.keys(parkIdsBySerializedCoordinates).map(serializedCoordinates =>
    serializedCoordinatesToCoordinates(serializedCoordinates as SerializedCoordinates)
  );

  return {
    ...previousState,
    allParkCoordinates,
    parkIdsBySerializedCoordinates,
    parksById
  };
}
