import { createSlice } from '@reduxjs/toolkit';

export type InstallBannerState = {
  isOpen: boolean | 'uninitialized';
};

export const initialState: InstallBannerState = {
  isOpen: 'uninitialized'
};

export const installBannerSlice = createSlice({
  name: 'installBanner',
  initialState,
  reducers: {
    /*
      Currently, updating the isOpen value does not control the banner's open state. 
      It simply serves as a reference to the current state set in InstallBanner. 
      In the future, we might want to refactor the banner logic so that it's state & snooze logic is controlled by redux.
    */
    setIsOpen: (state, action: { payload: boolean }) => {
      state.isOpen = action.payload;
    }
  }
});

export const { setIsOpen } = installBannerSlice.actions;
export default installBannerSlice.reducer;
