import { useSelector } from 'react-redux';
import { useMediaQuery } from '@alltrails/core';
import { BREAKPOINT_SM_SIZE } from '../tokens';

const useIsMobileSizedScreen = () => {
  const isMobileBrowser = useSelector((state: { context: { mobileBrowser: boolean } }) => state.context?.mobileBrowser);
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_SM_SIZE}px)`, isMobileBrowser);

  return isMobile;
};

export default useIsMobileSizedScreen;
