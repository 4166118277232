import { Coordinates } from '../types/Geo';
import { SerializedCoordinates } from '../types/Results';

export const coordinatesToSerializedCoordinates = (coordinates: { lat: number; lng: number }): SerializedCoordinates => {
  return `${coordinates.lat}:${coordinates.lng}` as SerializedCoordinates;
};

export const serializedCoordinatesToCoordinates = (serializedCoordinates: SerializedCoordinates): Coordinates => {
  const [lat, lng] = serializedCoordinates.split(':').map(Number);
  return { lat, lng };
};
